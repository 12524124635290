import axios from "axios";
import GhostContentAPI from "@tryghost/content-api";
import authHeader from "./auth-header";

var moment = require("moment");
var qs = require("querystring");

const API_URL = process.env.REACT_APP_API_URL;
const Ghost_url = process.env.REACT_APP_GHOST_URL;
const api = new GhostContentAPI({
  url: process.env.REACT_APP_GHOST_URL,
  key: process.env.REACT_APP_GHOST_CONTENT_KEY,
  version: "v5.0",
});
const getPosts = (page = 1) => {
  const date = new Date();
  const last_month = moment(
    new Date(date.getFullYear(), date.getMonth() - 1, 1)
  ).format("YYYY-MM-DD HH:mm:ss");
  const last_week = moment(
    new Date(date.getFullYear(), date.getMonth(), date.getDate() - 7)
  ).format("YYYY-MM-DD HH:mm:ss");
  var posts_month_from_ghost = api.posts.browse({
    include: "authors,tags",
    filter: "published_at:>'" + last_month + "'",
  });
  var posts_week_from_ghost = api.posts.browse({
    include: "authors,tags",
    filter: "published_at:>'" + last_week + "'",
  });

  var posts_from_ghost = api.posts.browse({
    limit: 16,
    page,
    include: "authors,tags",
  });
  var post_from_back = axios.get(API_URL + "blog/");

  return Promise.all([
    posts_from_ghost,
    post_from_back,
    posts_month_from_ghost,
    posts_week_from_ghost,
  ]).then((values) => {
    var result = [];
    var result_l_m = [];
    var result_w_m = [];
    var ids = [];
    values[1].data.posts.forEach(function (c) {
      ids.push(c.post_id);
    });
    values[0].forEach(function (o) {
      var key = ids.findIndex((i) => i === o.id);
      result.push(Object.assign({}, values[1].data.posts[key], o));
    });
    values[2].forEach(function (o) {
      var key = ids.findIndex((i) => i === o.id);
      result_l_m.push(Object.assign({}, values[1].data.posts[key], o));
    });
    result_l_m?.sort((a, b) => (a.views > b.views ? -1 : 1));
    result_l_m = result_l_m.slice(0, 7);
    result_l_m = result_l_m.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / 4);

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []; // start a new chunk
      }

      resultArray[chunkIndex].push(item);

      return resultArray;
    }, []);

    values[3].forEach(function (o) {
      var key = ids.findIndex((i) => i === o.id);
      result_w_m.push(Object.assign({}, values[1].data.posts[key], o));
    });
    result_w_m?.sort((a, b) => (a.views > b.views ? -1 : 1));
    result_w_m = result_w_m.slice(0, 7);
    result_w_m = result_w_m.reduce((resultArray, item, index) => {
      const chunkIndex = Math.floor(index / 4);

      if (!resultArray[chunkIndex]) {
        resultArray[chunkIndex] = []; // start a new chunk
      }

      resultArray[chunkIndex].push(item);

      return resultArray;
    }, []);

    return {
      posts: result,
      meta: values[0].meta,
      last_month: result_l_m,
      last_week: result_w_m,
    };
  });
};

const getPostByslug = (slug) => {
  var posts_from_ghost = api.posts.read(
    {
      slug,
    },
    { include: "authors,tags" }
  );
  var post_from_back = axios.get(API_URL + "blog/");

  return Promise.all([posts_from_ghost, post_from_back]).then((values) => {
    var post_like_and_view = values[1].data.posts.filter(
      (p) => p.post_id === values[0].id
    );
    return Object.assign({}, values[0], post_like_and_view[0]);
  });
};
const getsimilarpost = (tag, id) => {
  var posts_from_ghost = api.posts.browse({
    // include: "tags",
    filter: "id:-" + id + "+tag:" + tag,
  });
  var post_from_back = axios.get(API_URL + "blog/ ", {
    headers: authHeader(),
  });

  return Promise.all([posts_from_ghost, post_from_back]).then((values) => {
    var result = [];
    var ids = [];
    values[1].data.posts.forEach((c) => {
      ids.push(c.post_id);
    });
    values[0].forEach((e) => {
      if (ids.includes(e.id)) {
        var key = ids.findIndex((i) => i === e.id);
        result.push(Object.assign({}, values[1].data.posts[key], e));
      }
    });
    return result;
  });
};
const getblogsearch = (word) => {
  return api.posts
    .browse({
      include: "tags",
    })
    .then((res) => {
      var all = [];

      res.filter((r) => {
        if (
          r.title?.includes(word) ||
          r.html?.includes(word) ||
          r.slug?.includes(word) ||
          r.authors?.includes(word) ||
          r.excerpt?.includes(word) ||
          r.tags?.includes(word)
        ) {
          all.push(r);
        }
      });

      return all ? all : [];
    });
};
const addpostview = (id) => {
  return axios.post(API_URL + "blog/add-post-view/", { post_id: id });
};
const likecomment = (comment_id) => {
  return axios.post(
    API_URL + "blog/comment/like/",
    { comment_id },
    {
      headers: authHeader(),
    }
  );
};
const removelikecomment = (comment_id) => {
  return axios.delete(API_URL + "blog/comment/like/", {
    headers: authHeader(),
    data: {
      comment_id,
    },
  });
};
const dislikecomment = (comment_id) => {
  return axios.post(
    API_URL + "blog/comment/dislike/",
    { comment_id },
    {
      headers: authHeader(),
    }
  );
};
const removedislikecomment = (comment_id) => {
  return axios.delete(API_URL + "blog/comment/dislike/", {
    headers: authHeader(),
    data: {
      comment_id,
    },
  });
};
const getpostcomment = async (id) => {
  var userlikecomment = await getuserlikedcomment().then((res) => {
    return res.data.liked_comments;
  });
  var userdislikecomment = await getuserdislikedcomment().then((res) => {
    return res.data.DisLiked_comments;
  });
  var data = { post_id: id };
  var getcomment = await axios
    .post(API_URL + "blog/getcomment/", qs.stringify(data), {
      headers: authHeader(),
    })
    .then((res) => {
      return res.data;
    });
  return Promise.all([getcomment, userlikecomment, userdislikecomment]).then(
    (values) => {
      var c = [];
      values[0].forEach((element) => {
        if (values[1].includes(element.id)) {
          element.user_like = true;
        } else {
          element.user_like = false;
        }
        if (values[2].includes(element.id)) {
          element.user_dislike = true;
        } else {
          element.user_dislike = false;
        }
        c.push(element);
      });
      return c;
    }
  );
};
const getcommentreplies = (id) => {
  return axios.get(Ghost_url + "/members/api/comments/" + id + "/replies/");
};
const getuserlikedcomment = () => {
  return axios.get(API_URL + "blog/comment/like/", {
    headers: authHeader(),
  });
};
const getuserdislikedcomment = () => {
  return axios.get(API_URL + "blog/comment/dislike/", {
    headers: authHeader(),
  });
};

const sendcomment = async (text, post_id, rate, reply_id) => {
  var data = {
    post_id,
    content: text,
    reply_id,
  };

  var x = await axios
    .post(API_URL + "blog/comment/", qs.stringify(data), {
      headers: authHeader(),
    })
    .then(async (res) => {
      if (res.status == 200) {
        await axios.post(
          API_URL + "blog/rate-post/",
          qs.stringify({ post_id, rate }),
          {
            headers: authHeader(),
          }
        );
      }

      return res;
    });
  return x;
};

export default {
  getPosts,
  getPostByslug,
  addpostview,
  getpostcomment,
  getsimilarpost,
  getcommentreplies,
  sendcomment,
  getblogsearch,
  likecomment,
  dislikecomment,
  getuserlikedcomment,
  removedislikecomment,
  removelikecomment,
};
