import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { useRef, useState } from "react";
import Select2 from "../Select/select2";
import { Country, City } from "country-state-city";
import profileImg from "../../Assets/images/unknown.png";
import { Link, Navigate } from "react-router-dom";
import userService from "../../services/user.service";
import { isEmail } from "validator";
import { Col, Row } from "react-bootstrap";
import { useEffect } from "react";
import ImageUploading from "react-images-uploading";
const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop + 100);

const required = (value) => {
  if (!value) {
    return (
      <div className="text-danger px-3" role="alert">
        This field is required!
      </div>
    );
  }
};

const email = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="text-danger px-3" role="alert">
        This is not a valid email.
      </div>
    );
  }
};
const countValidationday = (value) => {
  // console.log(typeof value);
  if (parseInt(value) === 0 || parseInt(value) > 31) {
    return (
      <div className="text-danger px-2" role="alert">
        The day must be between 1 and 31.
      </div>
    );
  }
};
const countValidationmonth = (value) => {
  // console.log(value);
  if (parseInt(value) === 0 || parseInt(value) > 12) {
    return (
      <div className="text-danger px-2" role="alert">
        The day must be between 1 and 12.
      </div>
    );
  }
};
const countValidationyear = (value, type) => {
  // console.log(value);
  if (value.length !== 4) {
    return (
      <div className="text-danger px-2" role="alert">
        The year must be 4 numbers.
      </div>
    );
  }
};

const GeneralComp = ({ userdata }) => {
  const [city, SetCity] = useState([]);
  const [gender, SetGender] = useState(
    userdata.sex ? (userdata?.sex === "f" ? "Female" : "Male") : "Female"
  );
  const [user, setUser] = useState({});
  const [useredit, setUserEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [fileUrl, setFileUrl] = useState(null);

  useEffect(() => {
    var b = userdata?.birthday?.split("-");
    if (typeof b !== "undefined") {
      userdata.y = b[0];
      userdata.m = b[1];
      userdata.d = b[2];
    }
    setUser(userdata);
  }, [userdata]);
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const url = URL.createObjectURL(file);
    setFileUrl(url);
  };
  const onChangeUserField = (e, f) => {
    const name = e.target.value;
    const c = { ...user };
    c[f] = name;
    setUser(c);
  };
  const fileInputRef = useRef(null);
  const Userform = useRef();
  const checkBtn = useRef();
  const executeScroll = () => scrollToRef(Userform);
  if (!localStorage.getItem("user")) {
    return <Navigate to="/login" />;
  }
  const handleButtonClick = (event) => {
    event.preventDefault();
    fileInputRef.current.click();
  };
  const EditUser = () => {
    setUserEdit((useredit) => !useredit);
  };
  const handleEditUser = async (e) => {
    e.preventDefault();
    setLoading((loading) => !loading);
    Userform.current.validateAll();
    if (checkBtn.current.context._errors.length === 0 && !loading) {
      const c = { ...user };
      c["birthday"] = c["y"] + "-" + c["m"] + "-" + c["d"];
      delete c.m;
      delete c.y;
      delete c.d;
      var header = "";
      var formData = new FormData();
      if (fileInputRef?.current?.files[0]?.name) {
        formData.append(
          "image",
          fileInputRef.current.files[0],
          fileInputRef.current.files[0].name
        );
      } else {
        if (userdata.image) {
          const defaultImageName = new URL(
            process.env.REACT_APP_MEDIA_API_URL + userdata.image
          ).pathname
            .split("/")
            .pop();
          const response = await fetch(
            process.env.REACT_APP_MEDIA_API_URL + userdata.image
          );
          const blob = await response.blob();
          const defaultImageFile = new File([blob], defaultImageName, {
            type: blob.type,
          });
          formData.append("image", defaultImageFile);
        } else {
          alert("Please upload your profile photo");
        }
      }
      header = "img";
      for (const key in c) {
        formData.append(key, c[key]);
      }

      var data = header == "img" ? formData : c;
      userService.editProfileUserInfo(data, header).then(
        (response) => {
          // console.log(response);
          setLoading((loading) => !loading);
          setUserEdit(false);
        },
        (error) => {
          setLoading((loading) => !loading);
        }
      );
    } else {
      setLoading((loading) => !loading);
    }
  };

  return (
    <div className="profile-container">
      <div className="profile-reg-1">General</div>
      <Form onSubmit={handleEditUser} ref={Userform}>
        <Row>
          <Col sm="12" lg="4" xl="6" className="profile-general-left">
            {useredit ? (
              <div className="profile-general-right-img mx-auto d-lg-none cursor-pointer">
                <div className="item-image w-100">
                  <img
                    src={
                      fileUrl
                        ? fileUrl
                        : process.env.REACT_APP_MEDIA_API_URL + userdata.image
                        ? process.env.REACT_APP_MEDIA_API_URL + userdata.image
                        : profileImg
                    }
                    alt="profile img"
                    className="w-100 h-100"
                    style={{ objectFit: "fill" }}
                    onClick={handleButtonClick}
                  />
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                </div>
              </div>
            ) : (
              <div className="profile-general-right-img mx-auto d-lg-none">
                <div className="item-image w-100">
                  {userdata?.image ? (
                    <img
                      src={
                        fileUrl
                          ? fileUrl
                          : process.env.REACT_APP_MEDIA_API_URL + userdata.image
                          ? process.env.REACT_APP_MEDIA_API_URL + userdata.image
                          : profileImg
                      }
                      alt="profile img"
                      className="w-100 h-100"
                      style={{ objectFit: "fill" }}
                    />
                  ) : (
                    <img
                      src={profileImg}
                      alt="profile img"
                      className="w-100 h-100"
                      style={{ objectFit: "fill" }}
                    />
                  )}
                </div>
              </div>
            )}

            <div className="form-group">
              <label htmlFor="name" className="form-label">
                Name:
              </label>
              <Input
                type="text"
                className="form-input profile-form-input"
                name="first_name"
                id="name"
                validations={[required]}
                value={user?.first_name || ""}
                onChange={(e) => onChangeUserField(e, "first_name")}
                placeholder="Enter your name please"
                disabled={useredit ? false : true}
              />
            </div>
            <div className="form-group">
              <label htmlFor="lastname" className="form-label">
                Last name:
              </label>
              <Input
                type="text"
                className="form-input profile-form-input"
                name="last_name"
                id="last_name"
                onChange={(e) => onChangeUserField(e, "last_name")}
                validations={[required]}
                value={user?.last_name || ""}
                placeholder="Enter your last name please"
                disabled={useredit ? false : true}
              />
            </div>
            <div className="form-group mt-5 mb-5">
              <label htmlFor="‌‌birthday" className="form-label">
                ‌‌Birthday:
              </label>
              <div className="d-flex flex-row birthday-container">
                <Input
                  type="text"
                  placeholder="mm"
                  value={user.m || ""}
                  validations={[required, countValidationmonth]}
                  className="form-input w-100"
                  name="month"
                  maxLength={2}
                  onChange={(e) => onChangeUserField(e, "m")}
                  id="month"
                  style={{ borderRadius: "60px 10px 10px 60px" }}
                  disabled={useredit ? false : true}
                />
                <span className="color-purple mx-2 d-flex align-items-center h2">
                  /
                </span>
                <Input
                  type="text"
                  className="form-input w-100 rounded"
                  name="day"
                  id="day"
                  onChange={(e) => onChangeUserField(e, "d")}
                  validations={[required, countValidationday]}
                  placeholder="dd"
                  disabled={useredit ? false : true}
                  value={user.d || ""}
                />
                <span className="color-purple mx-2 d-flex align-items-center h2">
                  /
                </span>
                <Input
                  type="text"
                  className="form-input w-100 "
                  name="year"
                  id="year"
                  maxLength={4}
                  onChange={(e) => onChangeUserField(e, "y")}
                  placeholder="yyyy"
                  validations={[required, countValidationyear]}
                  style={{ borderRadius: "10px 60px 60px 10px" }}
                  disabled={useredit ? false : true}
                  value={user?.y || ""}
                />
              </div>
            </div>
            <div className="form-group mt-5 mb-5">
              <label htmlFor="‌‌birthday" className="form-label">
                Sex:
              </label>
              <div className="d-flex flex-row profile-left-c flex-wrap">
                <div className="profile-switch">
                  <div
                    className="profile-switch-active"
                    style={{
                      transform: `translateX(${
                        gender === "Male" ? "-100" : "0"
                      }%)`,
                      transition: "all 0.3s",
                    }}
                  >
                    {gender}
                  </div>
                  <span
                    className={`w-50 ${gender === "Male" ? "active" : ""}`}
                    onClick={() => {
                      useredit && SetGender("Male");
                    }}
                  >
                    Male
                  </span>
                  <span
                    className={`w-50 ${gender === "Female" ? "active" : ""}`}
                    onClick={() => {
                      useredit && SetGender("Female");
                    }}
                  >
                    Female
                  </span>
                  <Input
                    type="hidden"
                    name="sex"
                    id="gender"
                    value={gender || ""}
                  />
                </div>
              </div>
            </div>
          </Col>
          <Col sm="12" lg="4" xl="3" className="profile-general-middle">
            <div className="form-group">
              <label htmlFor="email" className="form-label">
                Email:
              </label>
              <Input
                type="text"
                className="form-input"
                name="email"
                id="email"
                validations={[required, email]}
                value={user.email || ""}
                onChange={(e) => onChangeUserField(e, "email")}
                placeholder="Enter your Email please"
                disabled={useredit ? false : true}
              />
            </div>
            <Link to="/resetpassword" className="profile-middle-button mb-5">
              Change password
            </Link>
            <div className="form-group mt-5">
              <label htmlFor="Country:" className="form-label">
                Country:
              </label>
              <Select2
                name="country"
                selected={user?.country}
                placeholder="Select your country please"
                data={Country.getAllCountries()}
                disabled={useredit ? false : true}
                callback={(e) => {
                  const c = { ...user };
                  c["country"] = e?.value;
                  var cityy = City.getCitiesOfCountry(e?.isoCode);
                  SetCity(cityy);
                  c["city"] = cityy[0] ? cityy[0]?.name : "";
                  setUser(c);
                }}
              />

              <Input
                type="hidden"
                name="country"
                value={user?.country || ""}
                validations={[required]}
              />
            </div>
          </Col>
          <Col sm="12" lg="4" xl="3" className="profile-general-right ">
            {useredit ? (
              <div className="profile-general-right-img mx-auto d-none d-lg-flex cursor-pointer">
                <div className="item-image w-100">
                  <img
                    src={
                      fileUrl
                        ? fileUrl
                        : userdata.image
                        ? process.env.REACT_APP_MEDIA_API_URL + userdata.image
                        : profileImg
                    }
                    alt="profile img"
                    className="w-100 h-100"
                    style={{ objectFit: "fill" }}
                    onClick={handleButtonClick}
                  />
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    onChange={handleFileChange}
                  />
                </div>
              </div>
            ) : (
              <div className="profile-general-right-img mx-auto d-none d-lg-flex">
                <div className="item-image w-100">
                  {userdata?.image ? (
                    <img
                      src={
                        fileUrl
                          ? fileUrl
                          : process.env.REACT_APP_MEDIA_API_URL + userdata.image
                          ? process.env.REACT_APP_MEDIA_API_URL + userdata.image
                          : profileImg
                      }
                      alt="profile img"
                      className="w-100 h-100"
                      style={{ objectFit: "fill" }}
                    />
                  ) : (
                    <img
                      src={profileImg}
                      alt="profile img"
                      className="w-100 h-100"
                      style={{ objectFit: "fill" }}
                    />
                  )}
                </div>
              </div>
            )}

            <div className="form-group mt-5">
              <label htmlFor="City:" className="form-label">
                City:
              </label>
              <Select2
                name="city"
                selected={
                  useredit
                    ? city.length
                      ? city[0].name
                      : user.city
                    : user.city
                }
                placeholder="Select your city please"
                data={city}
                disabled={useredit ? (city.length ? false : true) : true}
                callback={(e) => {
                  // console.log(e);
                  const c = { ...user };
                  c["city"] = e?.value;
                  setUser(c);
                }}
              />

              <Input
                type="hidden"
                name="city"
                value={user.city || ""}
                validations={[required]}
              />
            </div>
          </Col>
        </Row>
        {useredit ? (
          <button
            className={`profile-right-button ${loading ? "disabled" : ""}`}
          >
            {loading && (
              <span className="spinner-border spinner-border-sm"></span>
            )}
            <div>Save</div>
          </button>
        ) : (
          <div
            className="profile-right-button"
            onClick={() => {
              EditUser();
              executeScroll();
            }}
          >
            Edit
          </div>
        )}
        <CheckButton
          className="profile-form-button"
          style={{ display: "none" }}
          ref={checkBtn}
        />
      </Form>
    </div>
  );
};

export default GeneralComp;
