import OwlCarousel from "react-owl-carousel";
import reviews1 from "../../Assets/images/reviews1.png";
import reviews2 from "../../Assets/images/reviews2.png";
import reviews3 from "../../Assets/images/reviews3.png";
import reviews4 from "../../Assets/images/reviews4.png";
import reviews5 from "../../Assets/images/reviews5.png";
import reviews6 from "../../Assets/images/reviews6.png";
import reviews7 from "../../Assets/images/reviews7.png";

const testimonials = [
  {
    name: "Sarah M.",
    image: reviews1,
    text: "I can't thank Healthy Pregnancy enough for their amazing support during my pregnancy journey. The personalized insights and predictions were incredibly helpful, and the expert consultations provided peace of mind. A must-have for any expecting mother!",
  },
  {
    name: "Jessica L.",
    image: reviews2,
    text: "As a first-time mom, I was overwhelmed, but Healthy Pregnancy made things so much easier. The app's AI predictions were spot on, and the community features allowed me to connect with other moms. It's been a lifeline for me!",
  },
  {
    name: "Emily W.",
    image: reviews3,
    text: "After experiencing complications in my first pregnancy, I needed extra assurance the second time around. Healthy Pregnancy's thorough analysis and support were invaluable. I felt safer and more informed.",
  },
  {
    name: "Maria G.",
    image: reviews4,
    text: "The Healthy Pregnancy app has been my go-to resource throughout my pregnancy. It's like having a virtual pregnancy companion. The AI predictions were impressively accurate, and the information is so well-researched.",
  },
  {
    name: "Laura P.",
    image: reviews5,
    text: "I've recommended Healthy Pregnancy to all my expecting friends. The combination of expert advice and AI-driven insights is unbeatable. It's like having a team of specialists in your pocket.",
  },
  {
    name: "Rachel S.",
    image: reviews6,
    text: "I've been using Healthy Pregnancy since the early stages of my pregnancy. The ability to track my progress, receive personalized tips, and connect with professionals has been a game-changer. I can't imagine my journey without it.",
  },
  {
    name: "Jennifer H.",
    image: reviews7,
    text: "Healthy Pregnancy helped me maintain a sense of control and confidence throughout my pregnancy. The convenience of having vital information and expert guidance at my fingertips was a game-changer. Thank you for making my journey healthier and happier!",
  },
];

const LandingCarouselComp = () => {
  return (
    <OwlCarousel
      items={2}
      className="owl-theme reviews-items w-100"
      loop
      nav={true}
      dots={false}
      stagePadding={400}
      autoplayHoverPause={true}
      navText={[
        '<i class="owl-left"><svg width="78" height="65" viewBox="0 0 78 65" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M33.614 63.3372C34.8885 64.4681 36.9065 64.4221 38.1207 63.235C39.3349 62.0479 39.2856 60.1685 38.011 59.0376L11.4424 35.4673H74.0625C75.8229 35.4673 77.25 34.1381 77.25 32.4986C77.25 30.859 75.8229 29.5298 74.0625 29.5298H11.442L38.011 5.95921C39.2856 4.82835 39.3345 2.94921 38.1207 1.76199C36.9065 0.574808 34.8885 0.529088 33.614 1.65994L2.08361 29.6323C1.36566 30.2692 0.940868 31.0815 0.809203 31.9262C0.770358 32.1114 0.75 32.303 0.75 32.4986C0.75 32.6949 0.770442 32.8865 0.809415 33.0721C0.941335 33.9164 1.36608 34.7283 2.08361 35.3648L33.614 63.3372Z" fill="#D7F171"/></svg></i>',
        '<i class="owl-right"><svg width="78" height="65" viewBox="0 0 78 65" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M44.386 1.66284C43.1115 0.531948 41.0935 0.577866 39.8793 1.76497C38.6651 2.95208 38.7144 4.83149 39.989 5.96239L66.5576 29.5327L3.9375 29.5327C2.17715 29.5327 0.75 30.8619 0.75 32.5014C0.75 34.141 2.17715 35.4702 3.9375 35.4702L66.558 35.4702L39.989 59.0408C38.7144 60.1716 38.6655 62.0508 39.8793 63.238C41.0935 64.4252 43.1115 64.4709 44.386 63.3401L75.9164 35.3677C76.6343 34.7308 77.0591 33.9185 77.1908 33.0738C77.2296 32.8886 77.25 32.697 77.25 32.5014C77.25 32.3051 77.2296 32.1135 77.1906 31.9279C77.0587 31.0836 76.6339 30.2717 75.9164 29.6352L44.386 1.66284Z" fill="#D7F171"/></svg></i>',
      ]}
      responsive={{
        0: { items: 1, margin: 8, stagePadding: 30, nav: false, autoplay: true },
        580: { items: 2, margin: 20, stagePadding: 50, nav: false, autoplay: true },
        768: { items: 2, margin: 20, stagePadding: 70, nav: false, autoplay: true },
        1000: { items: 2, margin: 60, stagePadding: 100, nav: false, autoplay: true },
        1250: { items: 2, margin: 40, stagePadding: 300, nav: true },
        1400: { items: 2, margin: 40, stagePadding: 350, nav: true },
      }}
    >
      {testimonials.map((testimonial, index) => (
        <div key={index} className="reviews-item item">
          <div className="cir-7">
            <img src={testimonial.image} alt={testimonial.name} className="w-100" />
          </div>
          <div className="reviews-item-title">{testimonial.name}</div>
          <div className="reviews-item-desc">
            <div className="scrollable-text">{testimonial.text}</div>
          </div>
        </div>
      ))}
    </OwlCarousel>
  );
};

export default LandingCarouselComp;
