import { useEffect, useState } from "react";
import userService from "../../services/user.service";
import { Link } from "react-router-dom";
const NotDataComp = () => {
  const [name, setName] = useState("");
  useEffect(() => {
    if (localStorage.getItem("user")) {
      userService.getProfile().then(
        (response) => {
          setName(response.data.user.first_name);
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }, []);
  return (
    <div className="container-md mx-md-auto">
      <div className="babycheck-box">
        {!name ? (
          <span className="spinner-border "></span>
        ) : (
          <>
            <div className="babycheck-notif">
              Dear {name ? name : ""} please complete your profile to do Baby
              check.
            </div>
            <Link to="/profile" className="babycheck-complete-button">
              Complete
            </Link>{" "}
          </>
        )}
      </div>
    </div>
  );
};

export default NotDataComp;
