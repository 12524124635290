import OwlCarousel from "react-owl-carousel";
import aboutus1 from "../../Assets/images/maryam.jpg";
import aboutus2 from "../../Assets/images/zahra.jpg";
import aboutus3 from "../../Assets/images/haniyeh.jpg";
import aboutus4 from "../../Assets/images/sara.jpg";

const AboutUsCarouselComp = () => {
  const u = [
    {
      img: aboutus1,
      name: "Maryam Jahanirad",
      job: "Sales and marketing",
      describtion:
        "Maryam, with her extensive background in midwifery and great experience in the field, is responsible for reaching out to and building relationships with gynecologists, obstetricians, midwives, and other potential partners in order to introduce them to the benefits of using the Healthy Pregnancy application as an expert assistant. Her expertise in the field allows her to effectively communicate the value and capabilities of the application to potential partners and customers, making her a valuable asset to the Healthy Pregnancy team and instrumental in the growth and success of the application.",
      linkedinId: "maryam-jahanirad-754a67205",
    },
    {
      img: aboutus2,
      name: "Zahra Barghi",
      job: "R&D",
      describtion:
        "Zahra is an expert in working with medical data and plays a crucial role in the development of our AI-based solutions. She is responsible for gathering and preparing data for training our AI algorithms and managing the data labeling process to ensure the highest level of accuracy and quality. Her knowledge and experience in working with medical data allows her to effectively collect and process the necessary data to train the algorithm, making her a valuable asset to our team and essential to the success of our AI-based predictive solutions.",
      linkedinId: "zahra-barghi-311a33220",
    },
    {
      img: aboutus3,
      name: "Haniyeh Soltanshah",
      job: "AI expert and backend developer",
      describtion:
        "Haniyeh, with her background in computer science, is a vital member of our team when it comes to the development and implementation of our AI-based solutions. She is responsible for integrating and implementing the AI algorithms, creating, testing, and deploying the code. Additionally, she is responsible for developing the website and back-end of the application, ensuring that it is user-friendly, efficient, and secure. Her expertise in computer science allows her to effectively bring the AI algorithms to life and create a seamless user experience, making her an essential part of our team and a key player in the success of our AI-based solutions.",
      linkedinId: "haniyeh-soltanshah-98370a1b4",
    },
    {
      img: aboutus4,
      name: "Sara Abdoli",
      job: "Business Development",
      describtion:
        "Sara, with her MBA background, is a valuable member of our team when it comes to the business development and strategic planning of our AI-based solutions. She is responsible for identifying market opportunities, negotiating and dealing with potential investors, and developing pricing strategies. Additionally, she is responsible for attending seminars and exhibitions to present the product to the target market alongside the sales and marketing team. Furthermore, she is responsible for planning and overseeing the day-to-day operations of the startup business, ensuring that the business is running smoothly and efficiently. With her business acumen and strategic mindset, she plays a critical role in the growth and success of our AI-based solutions.",
      linkedinId: "sarahabdoli",
    },
  ];

  return (
    <OwlCarousel
      items={4}
      autoplay={true}
      className="owl-theme reviews-items w-100"
      loop
      nav={false}
      dots={false}
      stagePadding={0}
      autoplayHoverPause={true}
      navText={[
        '<i class="owl-left"><svg width="78" height="65" viewBox="0 0 78 65" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M33.614 63.3372C34.8885 64.4681 36.9065 64.4221 38.1207 63.235C39.3349 62.0479 39.2856 60.1685 38.011 59.0376L11.4424 35.4673H74.0625C75.8229 35.4673 77.25 34.1381 77.25 32.4986C77.25 30.859 75.8229 29.5298 74.0625 29.5298H11.442L38.011 5.95921C39.2856 4.82835 39.3345 2.94921 38.1207 1.76199C36.9065 0.574808 34.8885 0.529088 33.614 1.65994L2.08361 29.6323C1.36566 30.2692 0.940868 31.0815 0.809203 31.9262C0.770358 32.1114 0.75 32.303 0.75 32.4986C0.75 32.6949 0.770442 32.8865 0.809415 33.0721C0.941335 33.9164 1.36608 34.7283 2.08361 35.3648L33.614 63.3372Z" fill="#D7F171"/></svg></i>',
        '<i class="owl-right"><svg width="78" height="65" viewBox="0 0 78 65" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M44.386 1.66284C43.1115 0.531948 41.0935 0.577866 39.8793 1.76497C38.6651 2.95208 38.7144 4.83149 39.989 5.96239L66.5576 29.5327L3.9375 29.5327C2.17715 29.5327 0.75 30.8619 0.75 32.5014C0.75 34.141 2.17715 35.4702 3.9375 35.4702L66.558 35.4702L39.989 59.0408C38.7144 60.1716 38.6655 62.0508 39.8793 63.238C41.0935 64.4252 43.1115 64.4709 44.386 63.3401L75.9164 35.3677C76.6343 34.7308 77.0591 33.9185 77.1908 33.0738C77.2296 32.8886 77.25 32.697 77.25 32.5014C77.25 32.3051 77.2296 32.1135 77.1906 31.9279C77.0587 31.0836 76.6339 30.2717 75.9164 29.6352L44.386 1.66284Z" fill="#D7F171"/></svg></i>',
      ]}
      responsive={{
        0: {
          items: 1,
          margin: 30,
          stagePadding: 0,
        },
        450: {
          items: 2,
          margin: 10,
          stagePadding: 0,
        },
        1000: {
          items: 3,
          margin: 40,
          stagePadding: 0,
        },
        1250: {
          items: 4,
          margin: 60,
          stagePadding: 0,
        },
        1400: {
          items: 4,
          margin: 80,
          stagePadding: 0,
        },
      }}
    >
      {u.map((p, o) => {
        return (
          <div className="item" key={o}>
            <div className="aboutus-slide" style={{ height: "100%" }}>
              <div className="img-box" style={{ height: "300px", overflow: "hidden" }}>
                <img
                  src={p.img}
                  alt={p.name}
                  className="m-0"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              </div>
              <div className="content" style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", height: "100%" }}>
                <div className="slide-title">{p.name}</div>
                <div className="slide-job">{p.job}</div>
                <div className="slide-description">{p.describtion}</div>
                <div className="slide-social" style={{ display: "flex", justifyContent: "center", gap: "10px" }}>
                  <a
                    href={`https://www.linkedin.com/in/${p.linkedinId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <svg
                      className="mx-4"
                      width="38"
                      height="38"
                      viewBox="0 0 38 38"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M33.5833 0.25C34.6884 0.25 35.7482 0.688987 36.5296 1.47039C37.311 2.25179 37.75 3.3116 37.75 4.41667V33.5833C37.75 34.6884 37.311 35.7482 36.5296 36.5296C35.7482 37.311 34.6884 37.75 33.5833 37.75H4.41667C3.3116 37.75 2.25179 37.311 1.47039 36.5296C0.688987 35.7482 0.25 34.6884 0.25 33.5833V4.41667C0.25 3.3116 0.688987 2.25179 1.47039 1.47039C2.25179 0.688987 3.3116 0.25 4.41667 0.25H33.5833ZM32.5417 32.5417V21.5C32.5417 19.6987 31.8261 17.9712 30.5524 16.6976C29.2787 15.4239 27.5513 14.7083 25.75 14.7083C23.9792 14.7083 21.9167 15.7917 20.9167 17.4167V15.1042H15.1042V32.5417H20.9167V22.2708C20.9167 20.6667 22.2083 19.3542 23.8125 19.3542C24.586 19.3542 25.3279 19.6615 25.8749 20.2084C26.4219 20.7554 26.7292 21.4973 26.7292 22.2708V32.5417H32.5417ZM8.33333 11.8333C9.26159 11.8333 10.1518 11.4646 10.8082 10.8082C11.4646 10.1518 11.8333 9.26159 11.8333 8.33333C11.8333 6.39583 10.2708 4.8125 8.33333 4.8125C7.39955 4.8125 6.50401 5.18344 5.84373 5.84373C5.18344 6.50401 4.8125 7.39955 4.8125 8.33333C4.8125 10.2708 6.39583 11.8333 8.33333 11.8333M11.2292 32.5417V15.1042H5.45833V32.5417H11.2292V32.5417Z"
                        fill="#05668D"
                      />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </OwlCarousel>
  );
};

export default AboutUsCarouselComp;
