import { Col, Container, Row } from "react-bootstrap";

import { useTitle } from "../../helper/Title";
import AboutUsCarouselComp from "../Carousels/AboutusCarousel";
import FooterComp from "../Footer";
import HeaderComp from "../Header";
const AboutusComp = () => {
  useTitle("About Us | Healthy Pregnancy");
  return (
    <>
      <HeaderComp />
      <div id="aboutus" className="contents">
        <div className="header-svg">
          <svg
            className="aboutus-cir-1"
            width="400"
            height="229"
            viewBox="0 0 565 229"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="282.5" cy="-53.5" r="282.5" fill="#39C5B9" />
          </svg>
          <svg
            className="aboutus-cir-2"
            width="267"
            height="158"
            viewBox="0 0 267 158"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="-15.5" cy="-124.5" r="282.5" fill="white" />
          </svg>
          <svg
            className="aboutus-cir-3"
            width="275"
            height="565"
            viewBox="0 0 275 565"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="-7.5" cy="282.5" r="282.5" fill="#D7F171" />
          </svg>
          <svg
            className="aboutus-cir-4"
            width="232"
            height="565"
            viewBox="0 0 232 565"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="282.5" cy="282.5" r="282.5" fill="#D7F171" />
          </svg>
        </div>

        <Container>
          <Row className="top-banner-aboutus p-0 m-0">
            <Col
              sm="12"
              className="flex-column d-flex justify-content-center align-items-center"
            >
              <div className="aboutus-top-box">
                Our mission is to empower expectant mothers and their healthcare
                providers with the latest technology, information, and resources
                to ensure a safe and healthy pregnancy to improve maternal
                health outcomes by providing accurate and timely predictions,
                recommendations and insights to expectant mothers and healthcare
                professionals, through a user-friendly and accessible online
                platform.
                <br />
                Our vision is to create a future where expectant
                mothers and healthcare professionals have access to a smart and
                reliable AI-powered platform that predicts and prevents
                potential maternal health risks, leading to healthier
                pregnancies and better outcomes for mothers and their babies. We
                strive to be the leading provider of AI-based predictive
                solutions for maternal healthcare.
              </div>
              <div className="positio-relative w-100">
                <div className="aboutus-middle-back-box">
                  Behind Healthy Pregnancy
                </div>
                <div className="aboutus-middle-box-slides">
                  <AboutUsCarouselComp />
                </div>
              </div>
              <div className="aboutus-bottom-box">
                <div className="aboutus-bottom-head">Contact Us</div>
                <div className="aboutus-bottom-body">
                  <div className="aboutus-bottom-body-left">
                    <div className="mt-0 mt-md-5">
                      <div className="aboutus-bottom-body-left-container">
                        <svg
                          width="50"
                          height="50"
                          viewBox="0 0 50 50"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.4988 47.4801L18.3543 42.6245C19.2326 41.7458 19.856 40.6451 20.1578 39.4399C20.4597 38.2347 20.4287 36.9702 20.0683 35.7812C19.7079 34.5922 19.0316 33.5233 18.1114 32.6886C17.1911 31.8538 16.0616 31.2844 14.8432 31.0412C16.3405 23.0343 24.096 15.2468 31.028 14.7621C31.2784 16.1233 31.9372 17.376 32.9168 18.3537C34.2043 19.641 35.9504 20.3642 37.771 20.3642C39.5916 20.3642 41.3377 19.641 42.6252 18.3537L47.4807 13.4996C48.768 12.2121 49.4912 10.466 49.4912 8.64538C49.4912 6.82475 48.768 5.07868 47.4807 3.79122C32.9168 -10.7727 -10.772 32.9162 3.79185 47.4801C5.07943 48.7666 6.82514 49.4893 8.64532 49.4893C10.4655 49.4893 12.2112 48.7666 13.4988 47.4801Z"
                            fill="#A460A9"
                          />
                        </svg>

                        <span className="mx-3 aboutus-bottom-body-left-text">
                          Telephone : (236) 638-4737
                        </span>
                      </div>
                      <div className="aboutus-bottom-body-left-container">
                        <svg
                          width="42"
                          height="34"
                          viewBox="0 0 42 34"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M37.667 0.333313H4.33366C2.04199 0.333313 0.187826 2.20831 0.187826 4.49998L0.166992 29.5C0.166992 31.7916 2.04199 33.6666 4.33366 33.6666H37.667C39.9587 33.6666 41.8337 31.7916 41.8337 29.5V4.49998C41.8337 2.20831 39.9587 0.333313 37.667 0.333313ZM36.8337 9.18748L22.1045 18.3958C21.4378 18.8125 20.5628 18.8125 19.8962 18.3958L5.16699 9.18748C4.95809 9.07021 4.77516 8.91177 4.62926 8.72176C4.48336 8.53174 4.37754 8.3141 4.31819 8.082C4.25884 7.8499 4.24721 7.60818 4.28399 7.37145C4.32076 7.13473 4.40519 6.90792 4.53216 6.70477C4.65913 6.50162 4.826 6.32635 5.02267 6.18955C5.21934 6.05276 5.44172 5.95729 5.67636 5.90894C5.91099 5.86058 6.153 5.86033 6.38773 5.9082C6.62246 5.95608 6.84504 6.05109 7.04199 6.18748L21.0003 14.9166L34.9587 6.18748C35.1556 6.05109 35.3782 5.95608 35.6129 5.9082C35.8477 5.86033 36.0897 5.86058 36.3243 5.90894C36.5589 5.95729 36.7813 6.05276 36.978 6.18955C37.1747 6.32635 37.3415 6.50162 37.4685 6.70477C37.5955 6.90792 37.6799 7.13473 37.7167 7.37145C37.7534 7.60818 37.7418 7.8499 37.6825 8.082C37.6231 8.3141 37.5173 8.53174 37.3714 8.72176C37.2255 8.91177 37.0426 9.07021 36.8337 9.18748Z"
                            fill="#A460A9"
                          />
                        </svg>

                        <span className="mx-3 aboutus-bottom-body-left-text">
                          Email : info@healthypregnancyapp.com
                        </span>
                      </div>
                      <div className="aboutus-bottom-body-left-container">
                        <svg
                          width="30"
                          height="42"
                          viewBox="0 0 30 42"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M15.0003 19.9584C13.619 19.9584 12.2942 19.4096 11.3175 18.4329C10.3407 17.4561 9.79199 16.1314 9.79199 14.75C9.79199 13.3687 10.3407 12.0439 11.3175 11.0672C12.2942 10.0904 13.619 9.54169 15.0003 9.54169C16.3817 9.54169 17.7064 10.0904 18.6832 11.0672C19.6599 12.0439 20.2087 13.3687 20.2087 14.75C20.2087 15.434 20.0739 16.1113 19.8122 16.7432C19.5505 17.3751 19.1668 17.9492 18.6832 18.4329C18.1995 18.9165 17.6254 19.3002 16.9935 19.5619C16.3616 19.8236 15.6843 19.9584 15.0003 19.9584ZM15.0003 0.166687C11.1326 0.166687 7.42326 1.70314 4.68835 4.43805C1.95345 7.17295 0.416992 10.8823 0.416992 14.75C0.416992 25.6875 15.0003 41.8334 15.0003 41.8334C15.0003 41.8334 29.5837 25.6875 29.5837 14.75C29.5837 10.8823 28.0472 7.17295 25.3123 4.43805C22.5774 1.70314 18.8681 0.166687 15.0003 0.166687Z"
                            fill="#A460A9"
                          />
                        </svg>

                        <span className="mx-3 aboutus-bottom-body-left-text">
                          Address : 777 Fort St, Victoria, BC V8W 1G9
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="aboutus-bottom-body-right">
                    <div style={{ display: 'flex', gap: '20px', justifyContent: 'center', alignItems: 'center' }}>
                      {/* LinkedIn Icon */}
                      <a
                        href="https://www.linkedin.com/company/healthy-pregnancy-startup/posts/?feedView=all"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="LinkedIn"
                        style={{ textDecoration: 'none' }}
                      >
                        <svg
                          width="76"
                          height="76"
                          viewBox="0 0 76 76"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M67.1667 0.5C69.3768 0.5 71.4964 1.37797 73.0592 2.94078C74.622 4.50358 75.5 6.6232 75.5 8.83333V67.1667C75.5 69.3768 74.622 71.4964 73.0592 73.0592C71.4964 74.622 69.3768 75.5 67.1667 75.5H8.83333C6.6232 75.5 4.50358 74.622 2.94078 73.0592C1.37797 71.4964 0.5 69.3768 0.5 67.1667V8.83333C0.5 6.6232 1.37797 4.50358 2.94078 2.94078C4.50358 1.37797 6.6232 0.5 8.83333 0.5H67.1667ZM65.0833 65.0833V43C65.0833 39.3975 63.6522 35.9425 61.1049 33.3951C58.5575 30.8478 55.1025 29.4167 51.5 29.4167C47.9583 29.4167 43.8333 31.5833 41.8333 34.8333V30.2083H30.2083V65.0833H41.8333V44.5417C41.8333 41.3333 44.4167 38.7083 47.625 38.7083C49.1721 38.7083 50.6558 39.3229 51.7498 40.4169C52.8438 41.5108 53.4583 42.9946 53.4583 44.5417V65.0833H65.0833ZM16.6667 23.6667C18.5232 23.6667 20.3037 22.9292 21.6164 21.6164C22.9292 20.3037 23.6667 18.5232 23.6667 16.6667C23.6667 12.7917 20.5417 9.625 16.6667 9.625C14.7991 9.625 13.008 10.3669 11.6875 11.6875C10.3669 13.008 9.625 14.7991 9.625 16.6667C9.625 20.5417 12.7917 23.6667 16.6667 23.6667ZM22.4583 65.0833V30.2083H10.9167V65.0833H22.4583Z"
                            fill="white"
                          />
                        </svg>
                      </a>
                      {/* Instagram Icon */}
                      <a
                        href="https://www.instagram.com/healthypregnancy/"
                        target="_blank"
                        rel="noopener noreferrer"
                        aria-label="Instagram"
                        style={{ textDecoration: 'none' }}
                      >
                        <svg
                          width="84"
                          height="84"
                          viewBox="0 0 84 84"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M24.4997 0.333313H59.4997C72.833 0.333313 83.6664 11.1666 83.6664 24.5V59.5C83.6663 65.9094 81.1202 72.0563 76.5881 76.5884C72.056 81.1205 65.9091 83.6667 59.4997 83.6667H24.4997C11.1663 83.6667 0.333008 72.8333 0.333008 59.5V24.5C0.333008 18.0906 2.87913 11.9437 7.41126 7.41157C11.9434 2.87944 18.0903 0.333313 24.4997 0.333313ZM23.6663 8.66665C19.6881 8.66665 15.8728 10.247 13.0597 13.06C10.2467 15.8731 8.66634 19.6884 8.66634 23.6666V60.3333C8.66634 68.625 15.3747 75.3333 23.6663 75.3333H60.333C64.3113 75.3333 68.1266 73.753 70.9396 70.9399C73.7527 68.1269 75.333 64.3116 75.333 60.3333V23.6666C75.333 15.375 68.6247 8.66665 60.333 8.66665H23.6663ZM63.8747 14.9166C65.256 14.9166 66.5808 15.4654 67.5575 16.4421C68.5343 17.4189 69.083 18.7436 69.083 20.125C69.083 21.5063 68.5343 22.8311 67.5575 23.8078C66.5808 24.7846 65.256 25.3333 63.8747 25.3333C62.4933 25.3333 61.1686 24.7846 60.1918 23.8078C59.2151 22.8311 58.6663 21.5063 58.6663 20.125C58.6663 18.7436 59.2151 17.4189 60.1918 16.4421C61.1686 15.4654 62.4933 14.9166 63.8747 14.9166ZM41.9997 21.1666C47.525 21.1666 52.8241 23.3616 56.7311 27.2686C60.6381 31.1756 62.833 36.4746 62.833 42C62.833 47.5253 60.6381 52.8244 56.7311 56.7314C52.8241 60.6384 47.525 62.8333 41.9997 62.8333C36.4743 62.8333 31.1753 60.6384 27.2683 56.7314C23.3613 52.8244 21.1663 47.5253 21.1663 42C21.1663 36.4746 23.3613 31.1756 27.2683 27.2686C31.1753 23.3616 36.4743 21.1666 41.9997 21.1666ZM41.9997 29.5C38.6845 29.5 35.505 30.8169 33.1608 33.1611C30.8166 35.5054 29.4997 38.6848 29.4997 42C29.4997 45.3152 30.8166 48.4946 33.1608 50.8388C35.505 53.183 38.6845 54.5 41.9997 54.5C45.3149 54.5 48.4943 53.183 50.8385 50.8388C53.1827 48.4946 54.4997 45.3152 54.4997 42C54.4997 38.6848 53.1827 35.5054 50.8385 33.1611C48.4943 30.8169 45.3149 29.5 41.9997 29.5Z"
                            fill="white"
                          />
                        </svg>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        {/* <div className="about-us-container"></div> */}
      </div>
      <FooterComp className="aboutus-footer" />
    </>
  );
};

export default AboutusComp;
