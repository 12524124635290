import axios from "axios";
import authHeader from "./auth-header";
const qs = require("querystring");
const API_URL = process.env.REACT_APP_API_URL;
const getProfile = () => {
  return axios.get(API_URL + "profile/", { headers: authHeader() });
};

const editProfileUserInfo = (data, header) => {
  var authhead = authHeader();
  authhead["content-Type"] = "multipart/form-data";
  var headers = authhead;
  var data = header == "img" ? data : data;
  // console.log(header);
  return axios.put(API_URL + "userinfo/", data, {
    headers,
  });
};
const editProfileUserImage = (data) => {
  var authhead = authHeader();
  authhead["content-type"] = "multipart/form-data";
  var headers = authhead;
  return axios.put(API_URL + "userinfo/", data, {
    headers,
  });
};
const editProfileProfileInfo = (data) => {
  return axios.put(API_URL + "profile/", qs.stringify(data), {
    headers: authHeader(),
  });
};
const getpredictions = () => {
  var anemia = axios.get(API_URL + "ai/anemia/", { headers: authHeader() });
  var diabet = axios.get(API_URL + "ai/diabet/", { headers: authHeader() });
  var ectopic = axios.get(API_URL + "ai/ectopic/", { headers: authHeader() });
  var hg = axios.get(API_URL + "ai/hg/", { headers: authHeader() });
  var stillbirth = axios.get(API_URL + "ai/stillbirth/", {
    headers: authHeader(),
  });

  return Promise.all([anemia, diabet, ectopic, hg, stillbirth]).then(
    (values) => {
      return {
        anemia: values[0].data.pred,
        diabet: values[1].data.pred,
        ectopic: values[2].data.pred,
        hg: values[3].data.pred,
        stillbirth: values[4].data.pred,
      };
    }
  );
};
export default {
  getProfile,
  editProfileUserInfo,
  editProfileProfileInfo,
  getpredictions,
  editProfileUserImage,
};
