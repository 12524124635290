import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTitle } from "../../helper/Title";

const PrivacyComp = () => {
  useTitle("Privacy Policy | Healthy Pregnancy");

  const sections = [
    {
      title: "Introduction",
      content: `We are committed to protecting the privacy of our users.
                    This privacy policy explains how we collect, use, and
                    disclose personal information in connection with our AI
                    health technology service (the "Service"). By accessing or
                    using the Service, you agree to be bound by this privacy
                    policy.`,
    },
    {
      title: "Information Collection",
      content: `We may collect personal information from you when you use
                    the Service, such as your name, email address, and other
                    contact information, as well as information about your
                    health and medical history. We may also collect information
                    about your use of the Service, such as the features you
                    access and the data you input.`,
    },
    {
      title: "Use of Personal Information",
      content: (
        <>
          <p>We may use your personal information for the following purposes:</p>
          <ul>
            <li>
              To provide the Service to you, including processing your data and providing
              you with personalized health information and analysis;
            </li>
            <li>
              To communicate with you, such as to provide you with updates on the Service
              or to respond to your inquiries;
            </li>
            <li>To improve the Service and develop new features;</li>
            <li>To comply with legal or regulatory requirements.</li>
          </ul>
        </>
      ),
    },
    {
      title: "Sharing of Personal Information",
      content: `We will not share your personal information with any third
                    party, except as described in this privacy policy or with
                    your consent. We may share your personal information with
                    our service providers, such as data analytics companies, to
                    help us improve the Service.`,
    },
    {
      title: "Data Security",
      content: `We take reasonable measures to protect your personal
                    information from unauthorized access, use, or disclosure.
                    However, we cannot guarantee the security of your personal
                    information, and you use the Service at your own risk.`,
    },
    {
      title: "Data Retention",
      content: `We will retain your personal information for as long as
                    necessary to provide the Service, comply with legal or
                    regulatory requirements, or as otherwise described in this
                    privacy policy.`,
    },
    {
      title: "Data Access and Control",
      content: `You have the right to access and control your personal
                    information, including the right to update or delete your
                    account. Please contact us if you would like to exercise
                    these rights.`,
    },
    {
      title: "Data Transfer",
      content: `Your personal information may be transferred to, and
                    processed in, countries other than the country in which you
                    are located. These countries may have data protection laws
                    that are different from, and less protective than, the laws
                    in your country.`,
    },
    {
      title: "Changes to this Privacy Policy",
      content: `We may update this privacy policy from time to time. If we
                    make changes to this privacy policy, we will notify you by
                    revising the date at the top of this policy and, in some
                    cases, providing you with additional notice (such as adding
                    a statement to our homepage or sending you an email
                    notification). Your continued use of the Service after the
                    effective date of the revised privacy policy will be subject
                    to the new privacy policy.`,
    },
    {
      title: "Contact Information",
      content: `If you have any questions about this privacy policy or our
                    data practices, please contact us by email or through our
                    website's contact form.`,
    }
  ];

  const [activeIndex, setActiveIndex] = useState(null);

  const toggleSection = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div id="Terms" className="contents">
      <div className="header-svg">
        <svg
          className="terms-cir-1"
          width="134"
          height="273"
          viewBox="0 0 267 273"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="-15.5" cy="-9.5" r="282.5" fill="#39C5B9" />
        </svg>
        <svg
          className="terms-cir-2"
          width="270"
          height="232"
          viewBox="0 0 565 232"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="282.5" cy="-50.5" r="282.5" fill="#D7F171" />
        </svg>
        <svg
          className="terms-cir-3"
          width="140"
          height="285"
          viewBox="0 0 275 285"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="-7.5" cy="282.5" r="282.5" fill="#D7F171" />
        </svg>
        <svg
          className="terms-cir-4"
          width="120"
          height="158"
          viewBox="0 0 232 158"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="282.5" cy="282.5" r="282.5" fill="white" />
        </svg>
      </div>

      <div className="container-md">
        <Row className="top-banner-login p-0 m-0">
          <Col
            sm="12"
            style={{marginTop:'50px'}}
            className="banner-left-login flex-column d-flex justify-content-center align-items-center"
          >
            <div className="terms-container">
              <div className="terms-title">Privacy Policy</div>
              <div className="terms-desc">
                {sections.map((section, index) => (
                  <div
                    className={`terms-box ${activeIndex === index ? "active" : ""
                      }`}
                    key={index}
                  >
                    <div
                      className="terms-header"
                      onClick={() => toggleSection(index)}
                    >
                      <h3>{section.title}</h3>
                      <button className="toggle-button">
                        {activeIndex === index ? "-" : "+"}
                      </button>
                    </div>
                    {activeIndex === index && (
                      <div className="terms-content">{section.content}</div>
                    )}
                  </div>
                ))}
              </div>
              <Link to="/" className="terms-button">
                Back
              </Link>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default PrivacyComp;
