import {
  REGISTER_SUCCESS,
  REGISTER_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  SET_MESSAGE,
  RESET_PASS_SUCCESS,
  RESET_PASS_FAIL,
} from "./types";
import AuthService from "../services/auth.service";
export const register = (data) => (dispatch) => {
  return AuthService.register(data).then(
    (response) => {
      dispatch({
        type: REGISTER_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: REGISTER_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
export const request_pass_reset = (data) => (dispatch) => {
  return AuthService.requestpasswordreset(data).then(
    (response) => {
      dispatch({
        type: RESET_PASS_SUCCESS,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: response.data.status,
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response && error.response.data.email[0]) ||
        error.data ||
        error.toString();
      dispatch({
        type: RESET_PASS_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
export const pass_reset_confirm = (data) => (dispatch) => {
  return AuthService.passwordresetconfirm(data).then(
    (response) => {
      dispatch({
        type: SET_MESSAGE,
        payload: response.data.status,
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};
export const profile_pass_reset = (data) => (dispatch) => {
  return AuthService.resetpass(data).then(
    (response) => {
      dispatch({
        type: SET_MESSAGE,
        payload: "OK",
      });
      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response && error.response.data) ||
        error.message ||
        error.toString();

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const login = (data) => (dispatch) => {
  return AuthService.login(data).then(
    (data) => {
      dispatch({
        type: LOGIN_SUCCESS,
        payload: { user: data },
      });

      return Promise.resolve();
    },
    (error) => {
      const message = error.response.data;
      dispatch({
        type: LOGIN_FAIL,
      });

      dispatch({
        type: SET_MESSAGE,
        payload: message,
      });

      return Promise.reject();
    }
  );
};

export const logout = () => (dispatch) => {
  AuthService.logout().then(() => {
    dispatch({
      type: LOGOUT,
    });
    window.location.reload();
  });
};
