import HeaderComp from "../Header";
import pngwing1 from "../../Assets/images/pngwing-1.png";
import { Col, Row } from "react-bootstrap";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useTitle } from "../../helper/Title";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import { register } from "../../actions/auth";
import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
const required = (value) => {
  if (!value) {
    return (
      <div className="text-danger px-2" role="alert">
        This field is required!
      </div>
    );
  }
};

const validEmail = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="text-danger px-2" role="alert">
        This is not a valid email.
      </div>
    );
  }
};

const vpassword = (value) => {
  if (value.length < 8 || value.length > 40) {
    return (
      <div className="text-danger px-2" role="alert">
        The password must be between 8 and 40 characters.
      </div>
    );
  }
};
const repeatpassword = (value, props, components) => {
  if (value !== components["password"][0].value) {
    return (
      <div className="text-danger px-2" role="alert">
        Passwords are not equal.
      </div>
    );
  }
};
const SignupComp = () => {
  useTitle("Signup | Healthy Pregnancy");
  let navigate = useNavigate();
  const form = useRef();
  const checkBtn = useRef();

  const [field, setField] = useState({
    email: "",
    first_name: "",
    last_name: "",
    password: "",
    repeat_password: "",
  });
  const [successful, setSuccessful] = useState(false);
  const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);

  const dispatch = useDispatch();
  if (isLoggedIn) {
    return <Navigate to="/profile" />;
  }
  const onChangeField = (e, f) => {
    const name = e.target.value;
    const c = { ...field };
    c[f] = name;
    setField(c);
  };
  const handleRegister = (e) => {
    e.preventDefault();

    setSuccessful(false);

    form.current.validateAll();
    if (checkBtn.current.context._errors.length === 0) {
      dispatch(register(field))
        .then(() => {
          window.scrollTo(0, 0);
          setSuccessful(true);
        })
        .catch(() => {
          setSuccessful(false);
        });
    }
  };
  return (
    <>
      <HeaderComp />
      <div id="login" className="contents">
        <div className="header-svg">
          <svg
            className="login-cir-1"
            width="184"
            height="171"
            viewBox="0 0 184 171"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="44" cy="31" r="140" fill="#39C5B9" />
          </svg>
          <svg
            className="register-cir-2"
            width="240"
            height="225"
            viewBox="0 0 240 225"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="100" cy="140" r="140" fill="#D7F171" />
          </svg>
          <svg
            className="login-cir-3"
            width="564"
            height="1200"
            viewBox="0 0 564 1200"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <ellipse cx="603.5" cy="480" rx="603.5" ry="613" fill="#39C5B9" />
          </svg>
        </div>
        <Row className="top-banner-login register p-0 pb-4 m-0">
          <Col
            xl="7"
            lg="12"
            sm="12"
            className="banner-left-signup banner-left-login d-flex justify-content-center align-items-center"
          >
            {message ? (
              <div
                className="login-form-signup d-flex flex-column justify-content-center align-items-center"
                style={{ marginBottom: "322px", marginTop: "164px" }}
              >
                {message && typeof message == "string" ? (
                  <>
                    <div className="checkmail-desc text-center text-success">
                      <div className="form-group px-lg-5">
                        <div className="text-success">
                          account successfully created.
                        </div>
                      </div>
                    </div>
                    <Link className="checkmail-button text-center" to="/login">
                      Login
                    </Link>
                  </>
                ) : (
                  <>
                    {" "}
                    <div className="checkmail-desc text-center">
                      <div className="form-group px-lg-5">
                        {Object.values(message).map((m, i) => {
                          return (
                            <div className="text-danger" key={i}>
                              {m[0]}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    <div
                      className="checkmail-button text-center cursor-pointer"
                      onClick={() => {
                        navigate(0);
                      }}
                    >
                      Signup
                    </div>
                  </>
                )}
              </div>
            ) : (
              <div className="login-form-signup">
                <Form onSubmit={handleRegister} ref={form}>
                  <div className="form-group">
                    <label htmlFor="name" className="form-label">
                      Name and Lastname
                    </label>
                    <div className="d-flex flex-row justify-content-between register-name">
                      <Input
                        type="text"
                        name="first_name"
                        id="first_name"
                        className="form-input"
                        placeholder="Please enter your name"
                        style={{
                          width: "98%",
                          marginRight: "6px",
                          paddingRight: "3px",
                          paddingLeft: "10px",
                        }}
                        value={field.first_name}
                        onChange={(e) => onChangeField(e, "first_name")}
                        validations={[required]}
                      />
                      <Input
                        type="text"
                        name="last_name"
                        id="last_name"
                        className="form-input"
                        placeholder="Please enter your lastname"
                        style={{
                          width: "100%",
                          marginRight: "6px",
                          paddingRight: "3px",
                          paddingLeft: "10px",
                        }}
                        value={field.last_name}
                        onChange={(e) => onChangeField(e, "last_name")}
                        validations={[required]}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="email" className="form-label">
                      Email
                    </label>
                    <Input
                      type="email"
                      name="email"
                      id="email"
                      className="form-input w-100"
                      placeholder="Please enter your Email"
                      value={field.email}
                      onChange={(e) => onChangeField(e, "email")}
                      validations={[required, validEmail]}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="password" className="form-label">
                      Password
                    </label>
                    <Input
                      type="password"
                      name="password"
                      id="password"
                      className="form-input w-100"
                      placeholder="Please enter your password"
                      value={field.password}
                      onChange={(e) => onChangeField(e, "password")}
                      validations={[required, vpassword]}
                    />
                  </div>
                  <div className="form-group">
                    <Input
                      type="password"
                      name="repassword"
                      id="repassword"
                      className="form-input w-100"
                      value={field.repeat_password}
                      onChange={(e) => onChangeField(e, "repeat_password")}
                      validations={[required, vpassword, repeatpassword]}
                      placeholder="Please enter your password again"
                    />
                  </div>
                  <div className="signup-d">
                    Note :
                    <br />
                    If you sign up that means you accept our
                    <Link className="signup-policy mx-1" to="/policy">
                      Privacy Policy
                    </Link>
                    and
                    <Link className="signup-policy mx-1" to="/terms">
                      Terms and Conditions
                    </Link>
                    .
                  </div>
                  <button
                    className="login-form-button"
                    style={{ marginTop: "4px" }}
                  >
                    Sign up
                  </button>

                  <CheckButton style={{ display: "none" }} ref={checkBtn} />
                </Form>
              </div>
            )}
          </Col>
          <Col xl="5" lg="12" sm="12" className="position-relative">
            <img
              src={pngwing1}
              alt="pngwing1"
              className="pngwing-login"
              style={{ borderRadius: "300px" }}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default SignupComp;
