import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import Select from "react-select";

const Select2 = ({ name, data, selected, disabled, placeholder, callback }) => {
  const [active, SetActive] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [select, SetSelect] = useState(selected ? selected : "");
  useEffect(() => {
    SetSelect({ label: selected, value: selected });
  }, [selected]);
  var data = data.map(({ name, isoCode }) => ({
    label: name,
    value: name,
    isoCode,
  }));
  const handleMenu1close = () => {
    setIsOpen1(false);
    setIsOpen2(false);
  };
  const handleMenu1open = () => {
    setIsOpen1(true);
    setIsOpen2(false);
  };
  const handleMenu2close = () => {
    setIsOpen1(false);
    setIsOpen2(false);
  };
  const handleMenu2open = () => {
    setIsOpen1(false);
    setIsOpen2(true);
  };

  return (
    <Select
      id={name}
      menuIsOpen={name == "city" ? isOpen1 : isOpen2}
      isSearchable={true}
      onMenuOpen={name == "city" ? handleMenu1open : handleMenu2open}
      onMenuClose={name == "city" ? handleMenu1close : handleMenu2close}
      //name={name}
      isDisabled={disabled}
      placeholder={placeholder}
      value={select.value ? select : ""}
      defaultValue={select.value ? select : ""}
      onChange={(e) => {
        setTimeout(() => {
          SetSelect({ label: e.label, value: e.label });
        }, 10);
        callback && callback(e);
      }}
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          border: state.isFocused ? "none" : "",
          boxShadow: "none",
          backgroundColor: state.isDisabled ? "unset" : "",
        }),
        placeholder: (baseStyles, state) => ({
          ...baseStyles,
          color: "var(--purple)",
        }),
        input: (baseStyles, state) => ({
          ...baseStyles,
          color: "var(--purple)",
        }),
        singleValue: (baseStyles, state) => ({
          ...baseStyles,
          color: "var(--purple)",
        }),
        menu: (baseStyles, state) => ({
          ...baseStyles,
          left: "0",
          top: "48px",
          borderRadius: "30px",
          zIndex: 51,
          height: "250px",
          overflowY: "hidden",
        }),
        menuList: (baseStyles, state) => ({
          ...baseStyles,
          padding: "20px",
        }),
        option: (baseStyles, state) => ({
          ...baseStyles,
          backgroundColor: state.isSelected ? "var(--purple)" : "",
          borderRadius: state.isSelected ? "15px" : "",
        }),
      }}
      classNames={{
        control: (state) => "border-0 w-100",
        dropdownIndicator: (state) => {
          var i = name == "city" ? isOpen1 : isOpen2;
          return `text-buster select-arrow ${i ? "active" : ""}`;
        },
        indicatorSeparator: () => "d-none",
      }}
      className="form-input d-flex justify-content-between  cursor-pointer px-3 align-items-center"
      options={data}
    />
  );
};

export default Select2;
