import HeaderComp from "../Header";
import pngwing1 from "../../Assets/images/pngwing-1.png";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTitle } from "../../helper/Title";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, useNavigate } from "react-router-dom";
import { isEmail } from "validator";
import { login } from "../../actions/auth";

const required = (value) => {
  if (!value) {
    return (
      <div className="text-danger px-3" role="alert">
        This field is required!
      </div>
    );
  }
};

const email = (value) => {
  if (!isEmail(value)) {
    return (
      <div className="text-danger px-3" role="alert">
        This is not a valid email.
      </div>
    );
  }
};

const LoginComp = () => {
  useTitle("Login | Healthy Pregnancy");
  let navigate = useNavigate();

  const form = useRef();
  const checkBtn = useRef();
  const [field, setField] = useState({
    email: "",
    password: "",
  });

  const onChangeField = (e, f) => {
    const name = e.target.value;
    const c = { ...field };
    c[f] = name;
    setField(c);
  };

  const [loading, setLoading] = useState(false);
  const [unsuccessful, setUnSuccessful] = useState(false);

  const { isLoggedIn } = useSelector((state) => state.auth);
  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();

  const handleLogin = (e) => {
    e.preventDefault();
    setLoading(true);
    form.current.validateAll();
    if (checkBtn.current.context._errors.length === 0) {
      dispatch(login(field))
        .then(() => {
          navigate("/profile");
          // window.location.reload();
        })
        .catch((e) => {
          setLoading(false);
          setUnSuccessful(true);
        });
    } else {
      setLoading(false);
    }
  };

  if (isLoggedIn) {
    return <Navigate to="/profile" />;
  }

  return (
    <>
      <HeaderComp />
      <div id="login" className="contents">
        <div className="header-svg">
          <svg
            className="login-cir-1"
            width="184"
            height="171"
            viewBox="0 0 184 171"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="44" cy="31" r="140" fill="#39C5B9" />
          </svg>
          <svg
            className="login-cir-2"
            width="240"
            height="225"
            viewBox="0 0 240 225"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="100" cy="140" r="140" fill="#D7F171" />
          </svg>
          <svg
            className="login-cir-3"
            width="564"
            height="1200"
            viewBox="0 0 564 1200"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <ellipse cx="603.5" cy="480" rx="603.5" ry="613" fill="#39C5B9" />
          </svg>
        </div>
        <Row className="top-banner-login p-0 m-0">
          <Col
            xl="7"
            lg="12"
            sm="12"
            className="banner-left banner-left-login d-flex justify-content-center align-items-center"
          >
            {unsuccessful && message?.non_field_errors ? (
              <div
                className="login-form-signup d-flex flex-column justify-content-center align-items-center"
                style={{ marginBottom: "322px", marginTop: "164px" }}
              >
                <div className="checkmail-desc text-center text-danger">
                  The email address or password is incorrect, please try again.
                </div>
                <div
                  className="checkmail-button text-center cursor-pointer"
                  onClick={() => {
                    navigate(0);
                  }}
                >
                  Login
                </div>
              </div>
            ) : (
              <div className="login-form-container">
                <Form onSubmit={handleLogin} ref={form}>
                  {/* {message?.non_field_errors && (
                    <div className="form-group px-lg-5">
                      <div className="text-danger">
                        {message?.non_field_errors[0]}
                      </div>
                    </div>
                  )} */}

                  <div className="form-group">
                    <label htmlFor="email" className="form-label">
                      Email
                    </label>

                    <Input
                      validations={[required, email]}
                      placeholder="Please enter your Email or username"
                      name="username"
                      value={field.email}
                      onChange={(e) => onChangeField(e, "email")}
                      className="form-input w-100"
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="password" className="form-label">
                      Password
                    </label>
                    <Input
                      type="password"
                      name="password"
                      id="password"
                      className="form-input w-100"
                      placeholder="Please enter your password"
                      value={field.password}
                      onChange={(e) => onChangeField(e, "password")}
                      validations={[required]}
                    />
                  </div>
                  <div className="form-group">
                    <button className="login-form-button" disabled={loading}>
                      {loading && (
                        <span className="spinner-border spinner-border-sm"></span>
                      )}
                      <span>Log in</span>
                    </button>
                  </div>

                  <CheckButton
                    className="login-form-button"
                    style={{ display: "none" }}
                    ref={checkBtn}
                  />

                  <Link to="/signup" className="login-form-d">
                    don’t you have an account? sign up
                  </Link>
                  <Link to="/resetpassrequest" className="login-form-d">
                    Forgot password ?
                  </Link>
                </Form>
              </div>
            )}
          </Col>
          <Col xl="5" lg="12" sm="12" className="position-relative">
            <img
              src={pngwing1}
              alt="pngwing1"
              className="pngwing-login"
              style={{ borderRadius: "300px" }}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default LoginComp;
