import { Link } from "react-router-dom";
const NotLoginComp = () => {
  return (
    <>
      <div className="babycheck-box">
        <div className="babycheck-notif">Please log in to do Baby check.</div>
        <Link to="/login" className="babycheck-complete-button">
          Log in
        </Link>
      </div>
    </>
  );
};

export default NotLoginComp;
