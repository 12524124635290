import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";

const ResetMessageComp = ({ email, message }) => {
  let navigate = useNavigate();
  return (
    <div className="checkmail-container">
      <div className="checkmail-desc">
        {message == "OK"
          ? "We have sent reset password link to " +
            email +
            " email please check out your email and click on the link in the email to reset your email.thanks"
          : message}
      </div>
      {message == "OK" ? (
        <Link
          className="checkmail-button"
          to={localStorage.getItem("user") ? "/profile" : "/login"}
        >
          Back
        </Link>
      ) : (
        <div
          className="checkmail-button cursor-pointer"
          style={{ width: "unset" }}
          onClick={() => {
            navigate(0);
          }}
        >
          Reset Password
        </div>
      )}
    </div>
  );
};

export default ResetMessageComp;
