import HeaderComp from "../Header";
import pngwing1 from "../../Assets/images/pngwing-1.png";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useTitle } from "../../helper/Title";
const CheckEmailComp = () => {
  useTitle("Check Email");
  return (
    <>
      <HeaderComp />
      <div id="login" className="contents">
        <svg
          className="login-cir-1"
          width="184"
          height="171"
          viewBox="0 0 184 171"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="44" cy="31" r="140" fill="#39C5B9" />
        </svg>
        <svg
          className="login-cir-2"
          width="240"
          height="225"
          viewBox="0 0 240 225"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="100" cy="140" r="140" fill="#D7F171" />
        </svg>
        <svg
          className="login-cir-3"
          width="564"
          height="1024"
          viewBox="0 0 564 1024"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <ellipse cx="603.5" cy="480" rx="603.5" ry="613" fill="#39C5B9" />
        </svg>
        <Row className="top-banner-login p-0 m-0">
          <Col
            lg="7"
            sm="12"
            className="banner-left d-flex justify-content-center align-items-center"
          >
            <div className="checkmail-container">
              <div className="checkmail-desc">
                We have sent you a verification email please chck out your email
                and click on the link in the email. tanks
              </div>
              <Link className="checkmail-button" to="/login">
                Back
              </Link>
            </div>
          </Col>
          <Col lg="5" sm="12">
            <img
              src={pngwing1}
              alt="pngwing1"
              className="pngwing-login"
              style={{ borderRadius: "300px" }}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default CheckEmailComp;
