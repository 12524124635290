import axios from "axios";
import authHeader from "./auth-header";
const qs = require("querystring");

const API_URL = process.env.REACT_APP_API_URL;
const config = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
  },
};
const register = (data) => {
  return axios.post(API_URL + "signup/", qs.stringify(data), config);
};

const requestpasswordreset = (data) => {
  return axios.post(API_URL + "password_reset/", qs.stringify(data), config);
};

const passwordresetconfirm = (data) => {
  return axios.post(
    API_URL + "password_reset/confirm/",
    qs.stringify(data),
    config
  );
};

const login = (data) => {
  return axios
    .post(API_URL + "login/", qs.stringify(data), config)
    .then((response) => {
      if (response.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }
      return response.data;
    });
};
const resetpass = (data) => {
  return axios.put(API_URL + "resetpass/", qs.stringify(data), {
    headers: authHeader(),
  });
};

const logout = () => {
  return axios
    .post(API_URL + "logout/", {}, { headers: authHeader() })
    .then(() => {
      localStorage.removeItem("user");
    });
};

export default {
  register,
  login,
  logout,
  requestpasswordreset,
  passwordresetconfirm,
  resetpass,
};
